@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package updater.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about: StringResource by 
      lazy { init_about() }

  public val account: StringResource by 
      lazy { init_account() }

  public val account_or_password_empty: StringResource by 
      lazy { init_account_or_password_empty() }

  public val android_version: StringResource by 
      lazy { init_android_version() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val big_version: StringResource by 
      lazy { init_big_version() }

  public val branch: StringResource by 
      lazy { init_branch() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val changelog: StringResource by 
      lazy { init_changelog() }

  public val code_name: StringResource by 
      lazy { init_code_name() }

  public val confirm: StringResource by 
      lazy { init_confirm() }

  public val copy_successful: StringResource by 
      lazy { init_copy_successful() }

  public val dark_mode: StringResource by 
      lazy { init_dark_mode() }

  public val dark_theme: StringResource by 
      lazy { init_dark_theme() }

  public val device_name: StringResource by 
      lazy { init_device_name() }

  public val download: StringResource by 
      lazy { init_download() }

  public val download_start: StringResource by 
      lazy { init_download_start() }

  public val extension_settings: StringResource by 
      lazy { init_extension_settings() }

  public val filename: StringResource by 
      lazy { init_filename() }

  public val filesize: StringResource by 
      lazy { init_filesize() }

  public val global: StringResource by 
      lazy { init_global() }

  public val join_group: StringResource by 
      lazy { init_join_group() }

  public val light_mode: StringResource by 
      lazy { init_light_mode() }

  public val logged_in: StringResource by 
      lazy { init_logged_in() }

  public val logging_in: StringResource by 
      lazy { init_logging_in() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_desc: StringResource by 
      lazy { init_login_desc() }

  public val login_error: StringResource by 
      lazy { init_login_error() }

  public val login_expired: StringResource by 
      lazy { init_login_expired() }

  public val login_expired_desc: StringResource by 
      lazy { init_login_expired_desc() }

  public val login_successful: StringResource by 
      lazy { init_login_successful() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val logout_confirm: StringResource by 
      lazy { init_logout_confirm() }

  public val logout_successful: StringResource by 
      lazy { init_logout_successful() }

  public val no_account: StringResource by 
      lazy { init_no_account() }

  public val opensource_info: StringResource by 
      lazy { init_opensource_info() }

  public val password: StringResource by 
      lazy { init_password() }

  public val regions_code: StringResource by 
      lazy { init_regions_code() }

  public val save_password: StringResource by 
      lazy { init_save_password() }

  public val security_error: StringResource by 
      lazy { init_security_error() }

  public val submit: StringResource by 
      lazy { init_submit() }

  public val system_default: StringResource by 
      lazy { init_system_default() }

  public val system_version: StringResource by 
      lazy { init_system_version() }

  public val toast_crash_info: StringResource by 
      lazy { init_toast_crash_info() }

  public val toast_ing: StringResource by 
      lazy { init_toast_ing() }

  public val toast_no_info: StringResource by 
      lazy { init_toast_no_info() }

  public val toast_success_info: StringResource by 
      lazy { init_toast_success_info() }

  public val toast_wrong_info: StringResource by 
      lazy { init_toast_wrong_info() }

  public val using_v2: StringResource by 
      lazy { init_using_v2() }

  public val view_source: StringResource by 
      lazy { init_view_source() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("about", CommonMainString0.about)
  map.put("account", CommonMainString0.account)
  map.put("account_or_password_empty", CommonMainString0.account_or_password_empty)
  map.put("android_version", CommonMainString0.android_version)
  map.put("app_name", CommonMainString0.app_name)
  map.put("big_version", CommonMainString0.big_version)
  map.put("branch", CommonMainString0.branch)
  map.put("cancel", CommonMainString0.cancel)
  map.put("changelog", CommonMainString0.changelog)
  map.put("code_name", CommonMainString0.code_name)
  map.put("confirm", CommonMainString0.confirm)
  map.put("copy_successful", CommonMainString0.copy_successful)
  map.put("dark_mode", CommonMainString0.dark_mode)
  map.put("dark_theme", CommonMainString0.dark_theme)
  map.put("device_name", CommonMainString0.device_name)
  map.put("download", CommonMainString0.download)
  map.put("download_start", CommonMainString0.download_start)
  map.put("extension_settings", CommonMainString0.extension_settings)
  map.put("filename", CommonMainString0.filename)
  map.put("filesize", CommonMainString0.filesize)
  map.put("global", CommonMainString0.global)
  map.put("join_group", CommonMainString0.join_group)
  map.put("light_mode", CommonMainString0.light_mode)
  map.put("logged_in", CommonMainString0.logged_in)
  map.put("logging_in", CommonMainString0.logging_in)
  map.put("login", CommonMainString0.login)
  map.put("login_desc", CommonMainString0.login_desc)
  map.put("login_error", CommonMainString0.login_error)
  map.put("login_expired", CommonMainString0.login_expired)
  map.put("login_expired_desc", CommonMainString0.login_expired_desc)
  map.put("login_successful", CommonMainString0.login_successful)
  map.put("logout", CommonMainString0.logout)
  map.put("logout_confirm", CommonMainString0.logout_confirm)
  map.put("logout_successful", CommonMainString0.logout_successful)
  map.put("no_account", CommonMainString0.no_account)
  map.put("opensource_info", CommonMainString0.opensource_info)
  map.put("password", CommonMainString0.password)
  map.put("regions_code", CommonMainString0.regions_code)
  map.put("save_password", CommonMainString0.save_password)
  map.put("security_error", CommonMainString0.security_error)
  map.put("submit", CommonMainString0.submit)
  map.put("system_default", CommonMainString0.system_default)
  map.put("system_version", CommonMainString0.system_version)
  map.put("toast_crash_info", CommonMainString0.toast_crash_info)
  map.put("toast_ing", CommonMainString0.toast_ing)
  map.put("toast_no_info", CommonMainString0.toast_no_info)
  map.put("toast_success_info", CommonMainString0.toast_success_info)
  map.put("toast_wrong_info", CommonMainString0.toast_wrong_info)
  map.put("using_v2", CommonMainString0.using_v2)
  map.put("view_source", CommonMainString0.view_source)
}

internal val Res.string.about: StringResource
  get() = CommonMainString0.about

private fun init_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about", "about",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    10, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    10, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    10, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    21),
    )
)

internal val Res.string.account: StringResource
  get() = CommonMainString0.account

private fun init_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:account", "account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    138, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    98, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    98, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 102,
    27),
    )
)

internal val Res.string.account_or_password_empty: StringResource
  get() = CommonMainString0.account_or_password_empty

private fun init_account_or_password_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:account_or_password_empty", "account_or_password_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    32, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    32, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    32, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 32,
    69),
    )
)

internal val Res.string.android_version: StringResource
  get() = CommonMainString0.android_version

private fun init_android_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:android_version", "android_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    174, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    122, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    122, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 130,
    43),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 174,
    28),
    )
)

internal val Res.string.big_version: StringResource
  get() = CommonMainString0.big_version

private fun init_big_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:big_version", "big_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    230, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    162, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    162, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 203,
    39),
    )
)

internal val Res.string.branch: StringResource
  get() = CommonMainString0.branch

private fun init_branch(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:branch", "branch",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    286, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    198, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    198, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 243,
    22),
    )
)

internal val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    317, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    229, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    229, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 266,
    22),
    )
)

internal val Res.string.changelog: StringResource
  get() = CommonMainString0.changelog

private fun init_changelog(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:changelog", "changelog",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    352, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    252, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    252, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 289,
    29),
    )
)

internal val Res.string.code_name: StringResource
  get() = CommonMainString0.code_name

private fun init_code_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:code_name", "code_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    386, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    286, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    286, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 319,
    29),
    )
)

internal val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    428, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    320, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    320, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 349,
    27),
    )
)

internal val Res.string.copy_successful: StringResource
  get() = CommonMainString0.copy_successful

private fun init_copy_successful(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:copy_successful", "copy_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    452, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    344, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    344, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 377,
    43),
    )
)

internal val Res.string.dark_mode: StringResource
  get() = CommonMainString0.dark_mode

private fun init_dark_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_mode", "dark_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    496, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    384, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    384, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 421,
    29),
    )
)

internal val Res.string.dark_theme: StringResource
  get() = CommonMainString0.dark_theme

private fun init_dark_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_theme", "dark_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    538, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    418, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    418, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 451,
    34),
    )
)

internal val Res.string.device_name: StringResource
  get() = CommonMainString0.device_name

private fun init_device_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:device_name", "device_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    577, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    453, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    453, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 486,
    35),
    )
)

internal val Res.string.download: StringResource
  get() = CommonMainString0.download

private fun init_download(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:download", "download",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    672, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    528, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    528, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 565,
    32),
    )
)

internal val Res.string.download_start: StringResource
  get() = CommonMainString0.download_start

private fun init_download_start(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:download_start", "download_start",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    617, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    489, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    489, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 522,
    42),
    )
)

internal val Res.string.extension_settings: StringResource
  get() = CommonMainString0.extension_settings

private fun init_extension_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:extension_settings", "extension_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    721, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    561, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    561, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 598,
    50),
    )
)

internal val Res.string.filename: StringResource
  get() = CommonMainString0.filename

private fun init_filename(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filename", "filename",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    776, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    604, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    604, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 649,
    28),
    )
)

internal val Res.string.filesize: StringResource
  get() = CommonMainString0.filesize

private fun init_filesize(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filesize", "filesize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    813, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    633, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    633, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 678,
    28),
    )
)

internal val Res.string.global: StringResource
  get() = CommonMainString0.global

private fun init_global(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:global", "global",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    858, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    666, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    666, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 707,
    34),
    )
)

internal val Res.string.join_group: StringResource
  get() = CommonMainString0.join_group

private fun init_join_group(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_group", "join_group",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    913, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    697, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    697, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 742,
    34),
    )
)

internal val Res.string.light_mode: StringResource
  get() = CommonMainString0.light_mode

private fun init_light_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:light_mode", "light_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    964, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    736, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    736, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 777,
    34),
    )
)

internal val Res.string.logged_in: StringResource
  get() = CommonMainString0.logged_in

private fun init_logged_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logged_in", "logged_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1007, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    771, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    771, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 812,
    29),
    )
)

internal val Res.string.logging_in: StringResource
  get() = CommonMainString0.logging_in

private fun init_logging_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logging_in", "logging_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1049, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    801, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    801, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 842,
    34),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1409, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1077, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1061, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1130,
    21),
    )
)

internal val Res.string.login_desc: StringResource
  get() = CommonMainString0.login_desc

private fun init_login_desc(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_desc", "login_desc",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1088, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    844, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    832, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 877,
    42),
    )
)

internal val Res.string.login_error: StringResource
  get() = CommonMainString0.login_error

private fun init_login_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_error", "login_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1155, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    895, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    879, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 920,
    55),
    )
)

internal val Res.string.login_expired: StringResource
  get() = CommonMainString0.login_expired

private fun init_login_expired(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_expired", "login_expired",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1306, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    994, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    978, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1039,
    41),
    )
)

internal val Res.string.login_expired_desc: StringResource
  get() = CommonMainString0.login_expired_desc

private fun init_login_expired_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_expired_desc", "login_expired_desc",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1231, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    943, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    927, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 976,
    62),
    )
)

internal val Res.string.login_successful: StringResource
  get() = CommonMainString0.login_successful

private fun init_login_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_successful", "login_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1360, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1036, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1020, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1081,
    48),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1580, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1200, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1184, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1269,
    22),
    )
)

internal val Res.string.logout_confirm: StringResource
  get() = CommonMainString0.logout_confirm

private fun init_logout_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout_confirm", "logout_confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1439, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1099, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1083, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1152,
    66),
    )
)

internal val Res.string.logout_successful: StringResource
  get() = CommonMainString0.logout_successful

private fun init_logout_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:logout_successful", "logout_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1526, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1158, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1142, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1219,
    49),
    )
)

internal val Res.string.no_account: StringResource
  get() = CommonMainString0.no_account

private fun init_no_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_account", "no_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1615, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1223, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1207, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1292,
    34),
    )
)

internal val Res.string.opensource_info: StringResource
  get() = CommonMainString0.opensource_info

private fun init_opensource_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:opensource_info", "opensource_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1678, 71),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1254, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1238, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1327,
    71),
    )
)

internal val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1750, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1330, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1314, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1399,
    28),
    )
)

internal val Res.string.regions_code: StringResource
  get() = CommonMainString0.regions_code

private fun init_regions_code(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:regions_code", "regions_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1787, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1355, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1339, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1428,
    36),
    )
)

internal val Res.string.save_password: StringResource
  get() = CommonMainString0.save_password

private fun init_save_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save_password", "save_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1828, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1392, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1376, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1465,
    41),
    )
)

internal val Res.string.security_error: StringResource
  get() = CommonMainString0.security_error

private fun init_security_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:security_error", "security_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1882, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1430, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1414, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1507,
    58),
    )
)

internal val Res.string.submit: StringResource
  get() = CommonMainString0.submit

private fun init_submit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:submit", "submit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1977, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1477, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1461, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1566,
    22),
    )
)

internal val Res.string.system_default: StringResource
  get() = CommonMainString0.system_default

private fun init_system_default(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_default", "system_default",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2000, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1500, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1484, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1589,
    42),
    )
)

internal val Res.string.system_version: StringResource
  get() = CommonMainString0.system_version

private fun init_system_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_version", "system_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2071, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1539, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1523, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1632,
    42),
    )
)

internal val Res.string.toast_crash_info: StringResource
  get() = CommonMainString0.toast_crash_info

private fun init_toast_crash_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_crash_info", "toast_crash_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2130, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1578, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1562, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1675,
    56),
    )
)

internal val Res.string.toast_ing: StringResource
  get() = CommonMainString0.toast_ing

private fun init_toast_ing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:toast_ing", "toast_ing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2215, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1635, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1615, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1732,
    33),
    )
)

internal val Res.string.toast_no_info: StringResource
  get() = CommonMainString0.toast_no_info

private fun init_toast_no_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:toast_no_info", "toast_no_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2249, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1673, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1653, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1766,
    41),
    )
)

internal val Res.string.toast_success_info: StringResource
  get() = CommonMainString0.toast_success_info

private fun init_toast_success_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_success_info", "toast_success_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2307, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1723, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1703, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1808,
    54),
    )
)

internal val Res.string.toast_wrong_info: StringResource
  get() = CommonMainString0.toast_wrong_info

private fun init_toast_wrong_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_wrong_info", "toast_wrong_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2386, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1770, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1750, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1863,
    68),
    )
)

internal val Res.string.using_v2: StringResource
  get() = CommonMainString0.using_v2

private fun init_using_v2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:using_v2", "using_v2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2483, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1827, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1807, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1932,
    40),
    )
)

internal val Res.string.view_source: StringResource
  get() = CommonMainString0.view_source

private fun init_view_source(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:view_source", "view_source",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2548, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1876, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1852, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1973,
    35),
    )
)
