@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package updater.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val icon: DrawableResource by 
      lazy { init_icon() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("icon", CommonMainDrawable0.icon)
}

internal val Res.drawable.icon: DrawableResource
  get() = CommonMainDrawable0.icon

private fun init_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/drawable/icon.webp", -1, -1),
    )
)
