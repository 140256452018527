@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package updater.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about: StringResource by 
      lazy { init_about() }

  public val account: StringResource by 
      lazy { init_account() }

  public val account_or_password_empty: StringResource by 
      lazy { init_account_or_password_empty() }

  public val android_version: StringResource by 
      lazy { init_android_version() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val big_version: StringResource by 
      lazy { init_big_version() }

  public val branch: StringResource by 
      lazy { init_branch() }

  public val build_time: StringResource by 
      lazy { init_build_time() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val changelog: StringResource by 
      lazy { init_changelog() }

  public val clear_search_history: StringResource by 
      lazy { init_clear_search_history() }

  public val code_name: StringResource by 
      lazy { init_code_name() }

  public val confirm: StringResource by 
      lazy { init_confirm() }

  public val copy_successful: StringResource by 
      lazy { init_copy_successful() }

  public val dark_mode: StringResource by 
      lazy { init_dark_mode() }

  public val dark_theme: StringResource by 
      lazy { init_dark_theme() }

  public val device_name: StringResource by 
      lazy { init_device_name() }

  public val download: StringResource by 
      lazy { init_download() }

  public val download_start: StringResource by 
      lazy { init_download_start() }

  public val extension_settings: StringResource by 
      lazy { init_extension_settings() }

  public val filename: StringResource by 
      lazy { init_filename() }

  public val filesize: StringResource by 
      lazy { init_filesize() }

  public val global: StringResource by 
      lazy { init_global() }

  public val join_channel: StringResource by 
      lazy { init_join_channel() }

  public val light_mode: StringResource by 
      lazy { init_light_mode() }

  public val logged_in: StringResource by 
      lazy { init_logged_in() }

  public val logging_in: StringResource by 
      lazy { init_logging_in() }

  public val login: StringResource by 
      lazy { init_login() }

  public val login_desc: StringResource by 
      lazy { init_login_desc() }

  public val login_error: StringResource by 
      lazy { init_login_error() }

  public val login_expired: StringResource by 
      lazy { init_login_expired() }

  public val login_expired_desc: StringResource by 
      lazy { init_login_expired_desc() }

  public val login_successful: StringResource by 
      lazy { init_login_successful() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val logout_confirm: StringResource by 
      lazy { init_logout_confirm() }

  public val logout_successful: StringResource by 
      lazy { init_logout_successful() }

  public val no_account: StringResource by 
      lazy { init_no_account() }

  public val opensource_info: StringResource by 
      lazy { init_opensource_info() }

  public val password: StringResource by 
      lazy { init_password() }

  public val regions_code: StringResource by 
      lazy { init_regions_code() }

  public val save_password: StringResource by 
      lazy { init_save_password() }

  public val search_history: StringResource by 
      lazy { init_search_history() }

  public val security_error: StringResource by 
      lazy { init_security_error() }

  public val security_patch_level: StringResource by 
      lazy { init_security_patch_level() }

  public val submit: StringResource by 
      lazy { init_submit() }

  public val system_default: StringResource by 
      lazy { init_system_default() }

  public val system_version: StringResource by 
      lazy { init_system_version() }

  public val toast_crash_info: StringResource by 
      lazy { init_toast_crash_info() }

  public val toast_ing: StringResource by 
      lazy { init_toast_ing() }

  public val toast_no_info: StringResource by 
      lazy { init_toast_no_info() }

  public val toast_no_ultimate_link: StringResource by 
      lazy { init_toast_no_ultimate_link() }

  public val toast_success_info: StringResource by 
      lazy { init_toast_success_info() }

  public val toast_wrong_info: StringResource by 
      lazy { init_toast_wrong_info() }

  public val using_v2: StringResource by 
      lazy { init_using_v2() }

  public val view_source: StringResource by 
      lazy { init_view_source() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("about", CommonMainString0.about)
  map.put("account", CommonMainString0.account)
  map.put("account_or_password_empty", CommonMainString0.account_or_password_empty)
  map.put("android_version", CommonMainString0.android_version)
  map.put("app_name", CommonMainString0.app_name)
  map.put("big_version", CommonMainString0.big_version)
  map.put("branch", CommonMainString0.branch)
  map.put("build_time", CommonMainString0.build_time)
  map.put("cancel", CommonMainString0.cancel)
  map.put("changelog", CommonMainString0.changelog)
  map.put("clear_search_history", CommonMainString0.clear_search_history)
  map.put("code_name", CommonMainString0.code_name)
  map.put("confirm", CommonMainString0.confirm)
  map.put("copy_successful", CommonMainString0.copy_successful)
  map.put("dark_mode", CommonMainString0.dark_mode)
  map.put("dark_theme", CommonMainString0.dark_theme)
  map.put("device_name", CommonMainString0.device_name)
  map.put("download", CommonMainString0.download)
  map.put("download_start", CommonMainString0.download_start)
  map.put("extension_settings", CommonMainString0.extension_settings)
  map.put("filename", CommonMainString0.filename)
  map.put("filesize", CommonMainString0.filesize)
  map.put("global", CommonMainString0.global)
  map.put("join_channel", CommonMainString0.join_channel)
  map.put("light_mode", CommonMainString0.light_mode)
  map.put("logged_in", CommonMainString0.logged_in)
  map.put("logging_in", CommonMainString0.logging_in)
  map.put("login", CommonMainString0.login)
  map.put("login_desc", CommonMainString0.login_desc)
  map.put("login_error", CommonMainString0.login_error)
  map.put("login_expired", CommonMainString0.login_expired)
  map.put("login_expired_desc", CommonMainString0.login_expired_desc)
  map.put("login_successful", CommonMainString0.login_successful)
  map.put("logout", CommonMainString0.logout)
  map.put("logout_confirm", CommonMainString0.logout_confirm)
  map.put("logout_successful", CommonMainString0.logout_successful)
  map.put("no_account", CommonMainString0.no_account)
  map.put("opensource_info", CommonMainString0.opensource_info)
  map.put("password", CommonMainString0.password)
  map.put("regions_code", CommonMainString0.regions_code)
  map.put("save_password", CommonMainString0.save_password)
  map.put("search_history", CommonMainString0.search_history)
  map.put("security_error", CommonMainString0.security_error)
  map.put("security_patch_level", CommonMainString0.security_patch_level)
  map.put("submit", CommonMainString0.submit)
  map.put("system_default", CommonMainString0.system_default)
  map.put("system_version", CommonMainString0.system_version)
  map.put("toast_crash_info", CommonMainString0.toast_crash_info)
  map.put("toast_ing", CommonMainString0.toast_ing)
  map.put("toast_no_info", CommonMainString0.toast_no_info)
  map.put("toast_no_ultimate_link", CommonMainString0.toast_no_ultimate_link)
  map.put("toast_success_info", CommonMainString0.toast_success_info)
  map.put("toast_wrong_info", CommonMainString0.toast_wrong_info)
  map.put("using_v2", CommonMainString0.using_v2)
  map.put("view_source", CommonMainString0.view_source)
}

internal val Res.string.about: StringResource
  get() = CommonMainString0.about

private fun init_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about", "about",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    10, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    10, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    10, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    10, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    21),
    )
)

internal val Res.string.account: StringResource
  get() = CommonMainString0.account

private fun init_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:account", "account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    138, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    94, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    98, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    98, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 102,
    27),
    )
)

internal val Res.string.account_or_password_empty: StringResource
  get() = CommonMainString0.account_or_password_empty

private fun init_account_or_password_empty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:account_or_password_empty", "account_or_password_empty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    32, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    32, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    32, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    32, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 32,
    69),
    )
)

internal val Res.string.android_version: StringResource
  get() = CommonMainString0.android_version

private fun init_android_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:android_version", "android_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    174, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    118, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    122, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    122, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 130,
    43),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 174,
    28),
    )
)

internal val Res.string.big_version: StringResource
  get() = CommonMainString0.big_version

private fun init_big_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:big_version", "big_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    230, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    166, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    162, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    162, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 203,
    39),
    )
)

internal val Res.string.branch: StringResource
  get() = CommonMainString0.branch

private fun init_branch(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:branch", "branch",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    286, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    210, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    198, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    198, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 243,
    22),
    )
)

internal val Res.string.build_time: StringResource
  get() = CommonMainString0.build_time

private fun init_build_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:build_time", "build_time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    317, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    233, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    229, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    229, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 266,
    34),
    )
)

internal val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    356, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    280, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    264, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    264, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 301,
    22),
    )
)

internal val Res.string.changelog: StringResource
  get() = CommonMainString0.changelog

private fun init_changelog(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:changelog", "changelog",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    391, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    307, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    287, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    287, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 324,
    29),
    )
)

internal val Res.string.clear_search_history: StringResource
  get() = CommonMainString0.clear_search_history

private fun init_clear_search_history(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:clear_search_history", "clear_search_history",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    425, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    357, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    321, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    321, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 354,
    56),
    )
)

internal val Res.string.code_name: StringResource
  get() = CommonMainString0.code_name

private fun init_code_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:code_name", "code_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    482, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    426, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    374, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    374, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 411,
    29),
    )
)

internal val Res.string.confirm: StringResource
  get() = CommonMainString0.confirm

private fun init_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirm", "confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    524, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    456, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    408, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    408, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 441,
    27),
    )
)

internal val Res.string.copy_successful: StringResource
  get() = CommonMainString0.copy_successful

private fun init_copy_successful(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:copy_successful", "copy_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    548, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    484, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    432, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    432, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 469,
    43),
    )
)

internal val Res.string.dark_mode: StringResource
  get() = CommonMainString0.dark_mode

private fun init_dark_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_mode", "dark_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    592, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    536, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    472, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    472, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 513,
    29),
    )
)

internal val Res.string.dark_theme: StringResource
  get() = CommonMainString0.dark_theme

private fun init_dark_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dark_theme", "dark_theme",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    634, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    570, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    506, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    506, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 543,
    34),
    )
)

internal val Res.string.device_name: StringResource
  get() = CommonMainString0.device_name

private fun init_device_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:device_name", "device_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    673, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    613, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    541, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    541, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 578,
    35),
    )
)

internal val Res.string.download: StringResource
  get() = CommonMainString0.download

private fun init_download(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:download", "download",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    768, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    708, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    616, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    616, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 657,
    32),
    )
)

internal val Res.string.download_start: StringResource
  get() = CommonMainString0.download_start

private fun init_download_start(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:download_start", "download_start",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    713, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    661, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    577, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    577, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 614,
    42),
    )
)

internal val Res.string.extension_settings: StringResource
  get() = CommonMainString0.extension_settings

private fun init_extension_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:extension_settings", "extension_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    817, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    745, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    649, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    649, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 690,
    50),
    )
)

internal val Res.string.filename: StringResource
  get() = CommonMainString0.filename

private fun init_filename(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filename", "filename",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    872, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    812, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    692, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    692, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 741,
    28),
    )
)

internal val Res.string.filesize: StringResource
  get() = CommonMainString0.filesize

private fun init_filesize(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filesize", "filesize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    909, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    849, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    721, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    721, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 770,
    28),
    )
)

internal val Res.string.global: StringResource
  get() = CommonMainString0.global

private fun init_global(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:global", "global",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    954, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    890, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    754, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    754, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 799,
    34),
    )
)

internal val Res.string.join_channel: StringResource
  get() = CommonMainString0.join_channel

private fun init_join_channel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_channel", "join_channel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1009, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    921, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    785, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    785, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 834,
    40),
    )
)

internal val Res.string.light_mode: StringResource
  get() = CommonMainString0.light_mode

private fun init_light_mode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:light_mode", "light_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1066, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    966, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    826, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    826, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 875,
    34),
    )
)

internal val Res.string.logged_in: StringResource
  get() = CommonMainString0.logged_in

private fun init_logged_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logged_in", "logged_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1109, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1001, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    861, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    861, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 910,
    29),
    )
)

internal val Res.string.logging_in: StringResource
  get() = CommonMainString0.logging_in

private fun init_logging_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logging_in", "logging_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1151, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1027, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    891, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    891, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 940,
    38),
    )
)

internal val Res.string.login: StringResource
  get() = CommonMainString0.login

private fun init_login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login", "login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1515, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1335, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1163, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1155, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1232,
    21),
    )
)

internal val Res.string.login_desc: StringResource
  get() = CommonMainString0.login_desc

private fun init_login_desc(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_desc", "login_desc",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1194, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1062, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    930, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    926, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 979,
    42),
    )
)

internal val Res.string.login_error: StringResource
  get() = CommonMainString0.login_error

private fun init_login_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_error", "login_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1261, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1109, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    981, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    973, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1022,
    55),
    )
)

internal val Res.string.login_expired: StringResource
  get() = CommonMainString0.login_expired

private fun init_login_expired(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_expired", "login_expired",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1412, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1244, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1080, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1072, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1141,
    41),
    )
)

internal val Res.string.login_expired_desc: StringResource
  get() = CommonMainString0.login_expired_desc

private fun init_login_expired_desc(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_expired_desc", "login_expired_desc",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1337, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1173, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1029, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1021, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1078,
    62),
    )
)

internal val Res.string.login_successful: StringResource
  get() = CommonMainString0.login_successful

private fun init_login_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:login_successful", "login_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1466, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1286, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1122, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1114, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1183,
    48),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1686, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1478, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1286, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1278, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1371,
    22),
    )
)

internal val Res.string.logout_confirm: StringResource
  get() = CommonMainString0.logout_confirm

private fun init_logout_confirm(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout_confirm", "logout_confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1545, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1357, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1185, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1177, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1254,
    66),
    )
)

internal val Res.string.logout_successful: StringResource
  get() = CommonMainString0.logout_successful

private fun init_logout_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:logout_successful", "logout_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1632, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1424, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1244, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1236, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1321,
    49),
    )
)

internal val Res.string.no_account: StringResource
  get() = CommonMainString0.no_account

private fun init_no_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_account", "no_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1721, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1501, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1309, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1301, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1394,
    34),
    )
)

internal val Res.string.opensource_info: StringResource
  get() = CommonMainString0.opensource_info

private fun init_opensource_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:opensource_info", "opensource_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1784, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1532, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1340, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1332, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1429,
    63),
    )
)

internal val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1848, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1596, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1408, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1400, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1493,
    28),
    )
)

internal val Res.string.regions_code: StringResource
  get() = CommonMainString0.regions_code

private fun init_regions_code(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:regions_code", "regions_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1885, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1621, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1433, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1425, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1522,
    36),
    )
)

internal val Res.string.save_password: StringResource
  get() = CommonMainString0.save_password

private fun init_save_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save_password", "save_password",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1926, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1670, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1470, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1462, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1559,
    41),
    )
)

internal val Res.string.search_history: StringResource
  get() = CommonMainString0.search_history

private fun init_search_history(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search_history", "search_history",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    1980, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1708, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1508, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1500, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1601,
    42),
    )
)

internal val Res.string.security_error: StringResource
  get() = CommonMainString0.security_error

private fun init_security_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:security_error", "security_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2019, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1763, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1547, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1539, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1644,
    58),
    )
)

internal val Res.string.security_patch_level: StringResource
  get() = CommonMainString0.security_patch_level

private fun init_security_patch_level(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:security_patch_level", "security_patch_level",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2114, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1834, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1594, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1586, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1703,
    56),
    )
)

internal val Res.string.submit: StringResource
  get() = CommonMainString0.submit

private fun init_submit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:submit", "submit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2195, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1903, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1647, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1639, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1760,
    22),
    )
)

internal val Res.string.system_default: StringResource
  get() = CommonMainString0.system_default

private fun init_system_default(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_default", "system_default",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2218, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1926, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1670, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1662, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1783,
    42),
    )
)

internal val Res.string.system_version: StringResource
  get() = CommonMainString0.system_version

private fun init_system_version(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:system_version", "system_version",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2289, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    1973, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1709, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1701, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1826,
    42),
    )
)

internal val Res.string.toast_crash_info: StringResource
  get() = CommonMainString0.toast_crash_info

private fun init_toast_crash_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_crash_info", "toast_crash_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2348, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2020, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1748, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1740, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1869,
    56),
    )
)

internal val Res.string.toast_ing: StringResource
  get() = CommonMainString0.toast_ing

private fun init_toast_ing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:toast_ing", "toast_ing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2433, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2077, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1805, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1793, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1926,
    33),
    )
)

internal val Res.string.toast_no_info: StringResource
  get() = CommonMainString0.toast_no_info

private fun init_toast_no_info(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:toast_no_info", "toast_no_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2467, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2115, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1843, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1831, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 1960,
    41),
    )
)

internal val Res.string.toast_no_ultimate_link: StringResource
  get() = CommonMainString0.toast_no_ultimate_link

private fun init_toast_no_ultimate_link(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_no_ultimate_link", "toast_no_ultimate_link",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2525, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2161, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1893, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1881, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 2002,
    70),
    )
)

internal val Res.string.toast_success_info: StringResource
  get() = CommonMainString0.toast_success_info

private fun init_toast_success_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_success_info", "toast_success_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2616, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2248, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    1968, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    1956, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 2073,
    54),
    )
)

internal val Res.string.toast_wrong_info: StringResource
  get() = CommonMainString0.toast_wrong_info

private fun init_toast_wrong_info(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:toast_wrong_info", "toast_wrong_info",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2695, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2311, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    2015, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    2003, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 2128,
    68),
    )
)

internal val Res.string.using_v2: StringResource
  get() = CommonMainString0.using_v2

private fun init_using_v2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:using_v2", "using_v2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2792, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2380, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    2072, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    2060, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 2197,
    40),
    )
)

internal val Res.string.view_source: StringResource
  get() = CommonMainString0.view_source

private fun init_view_source(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:view_source", "view_source",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("ja"),
    org.jetbrains.compose.resources.RegionQualifier("JP"), ),
    "composeResources/updater.composeapp.generated.resources/values-ja-rJP/strings.commonMain.cvr",
    2857, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    org.jetbrains.compose.resources.RegionQualifier("BR"), ),
    "composeResources/updater.composeapp.generated.resources/values-pt-rBR/strings.commonMain.cvr",
    2425, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("CN"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rCN/strings.commonMain.cvr",
    2121, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("zh"),
    org.jetbrains.compose.resources.RegionQualifier("TW"), ),
    "composeResources/updater.composeapp.generated.resources/values-zh-rTW/strings.commonMain.cvr",
    2105, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/updater.composeapp.generated.resources/values/strings.commonMain.cvr", 2238,
    35),
    )
)
